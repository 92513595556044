import React from "react";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { baseURL } from "../App";


const Main = () => {
    const { url } = useParams();

    const [ozelSayfa, setOzelSayfa] = useState(null);


    useEffect(() => {
        const getData = async () => {
            try {
                axios
                    .get(`${baseURL}items/ozel_sayfalar?fields=*.*`)
                    .then((response) => {
                        setOzelSayfa(response.data.data.filter(e => e.link == url)[0]);
                        console.log(response.data.data, "ozel_sayfalar.data.data");
                    })
                    .catch((error) => {
                        console.error("Error fetching data from Directus:", error);
                    });
            } catch (error) {
                console.error("An error occurred in useEffect:", error);
            }
        };
        getData();
    }, [url]);




    return (
        <>

            <div
                dangerouslySetInnerHTML={{
                    __html:
                        ozelSayfa && ozelSayfa.icerik,
                }}
            ></div>


        </>

    );
};

export default Main;
