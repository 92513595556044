import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // BrowserRouter olarak isimlendirme
import Main from './components/Politika';



export const lang = "tr-TR";
export const baseURL = "https://gizlilik-panel.yunsoftyazilim.com/";



function App() {
  return (
    <Router> {/* Router bileşenini kullanarak Routes bileşenini sarmala */}
      <Routes>
        <Route path="/" element={<h1>anasayfa</h1>} />

        <Route path="/politikalar">
          <Route index={true} element={<h1>Politikalar sayfası</h1>} />
          <Route path={":url"} element={<Main />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
